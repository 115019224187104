<template>
  <b-overlay :show="loading">
    <b-table
      striped
      hover
      responsive
      small
      class="position-relative"
      :fields="fields"
      :items="[equityHistory]"
    >

      <template #cell(date)="data">
        {{ data.item.date.substring(0,10) }}
      </template>

      <template #cell(highest_datetime)="data">
        {{ utcDate(data.item.highest_datetime) }}
      </template>

      <template #cell(lowest_datetime)="data">
        {{ utcDate(data.item.lowest_datetime) }}
      </template>

      <template #cell(open)="data">
        <b-badge
          variant="light-warning"
          class="p-50 font-medium-1"
        >
          {{ data.item.open }}
        </b-badge>
        <b-form-group class="m-50">
          <b-form-input
            v-model.number="form.open"
            type="number"
          />
        </b-form-group>
      </template>

      <template #cell(open_balance)="data">
        <b-badge
          variant="light-warning"
          class="p-50 font-medium-1"
        >
          {{ data.item.open_balance }}
        </b-badge>
        <b-form-group class="m-50">
          <b-form-input
            v-model.number="form.open_balance"
            type="number"
          />
        </b-form-group>
      </template>

    </b-table>
    <b-button
      variant="outline-success"
      class="float-right"
      @click="onSave"
    >
      Save
    </b-button>
  </b-overlay>
</template>
<script>
import {
  BTable, BFormGroup, BFormInput, BBadge, BButton, BOverlay,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  formatCurrency, formatDate, formatDateTime, utcDate,
} from '@/plugins/formaters'
import AccountApi from '@/api/account'

export default {
  components: {
    BTable, BFormInput, BFormGroup, BBadge, BButton, BOverlay,
  },
  props: {
    equityHistory: Object,
  },
  data() {
    return {
      fields: [
        { key: 'date', label: 'Day', sortable: true },
        { key: 'lowest', label: 'Lowest equity', sortable: true },
        { key: 'lowest_datetime', label: 'LE time', sortable: true },
        { key: 'highest', label: 'Highest equity', sortable: true },
        { key: 'highest_datetime', label: 'HE time', sortable: true },
        { key: 'open', label: 'Open equity', sortable: true },
        { key: 'open_balance', label: 'Open balance', sortable: true },
        { key: 'close', label: 'Close equity', sortable: true },
        { key: 'close_balance', label: 'Close balance', sortable: true },
      ],
      form: {
        open: this.equityHistory.open,
        open_balance: this.equityHistory.open_balance,
      },
      loading: false,
    }
  },
  methods: {
    formatDateTime,
    formatCurrency,
    formatDate,
    utcDate,
    onSave() {
      this.loading = true
      AccountApi.patchEquityHistory(this.equityHistory.id, this.form)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Equity is updated now.',
              icon: 'WarningIcon',
              variant: 'success',
            },
          })
          this.$emit('completed')
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
