import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  methods: {
    onCopySuccess() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Copied',
          icon: 'CopyIcon',
          text: 'You just copied the following text to the clipboard',
          variant: 'success',
        },
      })
    },
    onCopyError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Failed to copy',
          icon: 'CopyIcon',
          text: 'Failed to copy the text to the clipboard',
          variant: 'danger',
        },
      })
    },
  },
}
