<template>
  <b-overlay
    :show="this.$parent.refreshing"
    :variant="skin"
    spinner-variant="primary"
  >
    <b-card no-body>
      <b-card-header>
        <b-card-title>Trading Volume</b-card-title>
      </b-card-header>

      <b-card-body class="position-relative">
        <vue-apex-charts
          type="bar"
          height="250"
          :options="volumeChart.chartOptions"
          :series="volumeChart.series"
        />
        <b-overlay
          :show="samples"
          no-wrap
          :variant="skin"
          blur="2px"
          opacity=".95"
        >
          <template #overlay>
            <div class="text-center">
              You do not have any finished trades yet.
            </div>
          </template>
        </b-overlay>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BOverlay,
} from 'bootstrap-vue'
import { get } from 'vuex-pathify'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@/../themeConfig'
import { formatNumber } from '@/plugins/formaters'
import { tradingVolume } from '../sample-data'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BOverlay,
  },
  props: {
    data: [Object, Array],
    samples: Boolean,
    currency: String,
  },
  computed: {
    ...get('appConfig@layout', ['skin']),
    chartData() {
      return this.samples ? tradingVolume : this.data
    },
    volumeChart() {
      return {
        series: [
          {
            name: 'Volume',
            data: Object.values(this.chartData),
          },
        ],
        chartOptions: {
          chart: {
            type: 'bar',
            toolbar: { show: false },
          },
          grid: {
            padding: {
              top: -20,
              bottom: -10,
            },
            yaxis: {
              lines: { show: true },
            },
          },
          xaxis: {
            categories: Object.keys(this.chartData),
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '0.7rem',
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          fill: {
            opacity: 0.5,
            colors: [$themeColors.primary],
          },
          stroke: {
            show: true,
            colors: [$themeColors.primary],
            width: 1,
          },
          plotOptions: {
            bar: {
              columnWidth: this.getPercentWidth(400, Object.keys(this.chartData).length, 30),
            },
          },
          yaxis: {
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '0.7rem',
              },
              formatter: val => formatNumber(val),
            },
          },
          tooltip: {
            shared: true,
            intersect: false,
            x: { show: true },
            y: {
              formatter: value => formatNumber(value),
            },
          },
        },
      }
    },
  },
  methods: {
    getPercentWidth(w, n, x) {
      const percentWidth = Math.round((x / (w / n)) * 100)
      return `${percentWidth}%`
    },
  },
}
</script>
