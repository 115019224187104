<template>
  <div>
    <b-card
      class="mb-0"
      no-body
    >
      <b-card-header class="px-1 pb-1 pt-1 d-none">
        <h4 class="font-small-4 mb-0">
          Payout Overview
        </h4>
      </b-card-header>
      <b-card-body class="pt-1 px-1 pb-0">
        <b-row class="px-50">
          <b-col
            v-for="(object, index) in withdrawalData"
            :key="index"
            class="col-12 col-md-6 px-50 mb-1"
          >
            <b-card
              :class="`bg-${skin==='dark'?'dark-gradient':'light-body'}`"
              class="mb-0 h-100"
              body-class="px-75 py-1"
            >
              <b-button
                class="d-flex p-0 v-center text-left  v-center withdrawal-overview-header"
                variant="link"
              >
                <feather-icon
                  :icon="object.icon"
                  size="22"
                  class="d-none d-sm-block text-primary mr-50"
                />
                <span class="text-header font-weight-bold font-medium-1">{{ object.name }}</span>
              </b-button>
              <div class="d-flex">
                <div class="w-100">
                  <div class="d-flex justify-content-between align-items-center">
                    <span>{{ object.item1 }}</span>
                    <span>{{ object.value1 }}</span>
                  </div>
                  <div class="d-flex justify-content-between align-items-center text-primary">
                    <span>{{ object.item2 }}</span>
                    <span>{{ object.value2 }}</span>
                  </div>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { get } from 'vuex-pathify'
import {
  BButton, BCard, BCardBody, BCardHeader, BCol, BRow,
} from 'bootstrap-vue'
import { $themeColors } from '@themeConfig'
import { formatDate, formatCurrency } from '@/plugins/formaters'

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BRow,
    BCol,
    BButton,
  },
  props: {
    account: Object,
    currency: String,
    payoutAvailability: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      timerRemaining: '',
      timer: null,
      chartOptions: {
        grid: {
          show: false,
          padding: {
            left: -15,
            right: -15,
            top: -12,
            bottom: -15,
          },
        },
        colors: [$themeColors.primary],
        plotOptions: {
          radialBar: {
            hollow: {
              size: '40%',
            },
            dataLabels: {
              showOn: 'always',
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
          },
        },
        stroke: {
          lineCap: 'round',
        },
      },
    }
  },
  beforeMount() {
    this.refreshTime()
    this.timer = setInterval(() => {
      this.refreshTime()
    }, 1000)
  },
  computed: {
    ...get('appConfig@layout', ['skin']),
    series() {
      return [Math.min(this.datePercent, 100)]
    },
    datePercent() {
      const d = new Date()
      return (d.getTime() - new Date(this.account.next_withdrawal_datetime).getTime()) * 100
    },
    withdrawalData() {
      const data = {}

      if (this.payoutAvailability !== null) {
        data.withdrawal_bestday = {
          type: 1,
          icon: 'PercentIcon',
          name: 'Best day rule',
          item1: 'Current best day profit',
          value1: formatCurrency(this.payoutAvailability.bestDay, this.currency),
          item2: 'Status',
          value2: this.payoutAvailability.available ? 'Passing' : 'Pending',
        }
      } else {
        data.withdrawal_date = {
          type: 1,
          icon: 'CalendarIcon',
          name: 'Payout date',
          item1: 'Next payout on',
          value1: this.account.next_withdrawal_datetime ? formatDate(this.account.next_withdrawal_datetime) : '-',
          item2: 'Time to next payout',
          value2: this.timerRemaining,
        }
      }

      data.withdrawal_profit = {
        type: 2,
        icon: 'DollarSignIcon',
        name: 'Performance result',
        item1: 'Total performance result',
        value1: formatCurrency(Math.max(this.account.profit, 0), this.currency),
        item2: 'Your performance result',
        value2: formatCurrency(Math.max(this.account.profit * (this.account.profit_split / 100), 0), this.currency),
      }

      return data
    },
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    refreshTime() {
      const d = new Date()
      let diff = (new Date(this.account.next_withdrawal_datetime).getTime() - d.getTime())
      let remaining = '-'
      if (this.account.next_withdrawal_datetime) {
        if (diff < 0) {
          remaining = 'Now'
        } else if (diff > 8.64e+7) {
          remaining = `${Math.floor(diff / 8.64e+7) + 1} days`
        } else {
          diff /= 1000
          const hours = Math.floor(diff / 3600)
          const minutes = Math.floor(diff / 60) % 60
          const seconds = Math.floor(diff % 60)

          remaining = [hours, minutes, seconds]
            .map(v => (v < 10 ? `0${v}` : v))
            .filter((v, i) => v !== '00' || i > 0)
            .join(':')
        }
      }
      this.timerRemaining = remaining
    },
  },
}
</script>

<style lang="scss" scoped>
.withdrawal-overview-header {
  margin-bottom: 15px;
}

</style>
