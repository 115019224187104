const tradingHistory = [
  {
    symbol: 'EURUSD',
    type: 1,
    open_datetime: '2021-05-05',
    open_price: 2659,
    close_datetime: '2021-05-09',
    close_price: 2975,
    volume: 15,
    profit: 160,
  },
  {
    symbol: 'GBPJPY',
    type: 1,
    open_datetime: '2021-03-20',
    open_price: 2659,
    close_datetime: '2021-03-22',
    close_price: 2975,
    volume: 15,
    profit: 689,
  },
  {
    symbol: 'USDAUD',
    type: 0,
    open_datetime: '2021-04-16',
    open_price: 986,
    close_datetime: '2021-04-21',
    close_price: 1600,
    volume: 10,
    profit: 12680,
  },
  {
    symbol: 'EURUSD',
    type: 1,
    open_datetime: '2021-04-16',
    open_price: 2659,
    close_datetime: '2021-05-25',
    close_price: 2975,
    volume: 15,
    profit: -500,
  },
]

const accountBalance = [
  25000,
  26823,
  29029,
  20359,
  18326,
  35265,
  36892,
]

const tradingVolume = {
  EURUSD: 45,
  GBPJPY: 18,
  USDAUD: 35,
  HRKCZK: 60,
}

const instrumentAnalysis = {
  ETHUSD: [272, -212],
  BTCUSD: [185, -176],
  GBPUSD: [22, -8],
  GBPJPY: [17, -5],
  EURUSD: [14, -2],
  XRPUSD: [13, -10],
  EURJPY: [9, -0],
  USDCHF: [8, -1],
  EURGBP: [8, -1],
  LTCUSD: [5, 0],
}

const longShort = {
  buy: [12520, -3052],
  sell: [11500, -8450],
}

const accountEquity = [
  {
    date: '2022-12-06T00:00:00+00:00',
    open: 250000,
    openBalance: 250000,
    highest: 264084.04,
    lowest: 244311.15,
    close: 255195.42,
    closeBalance: 255195.42,
    profit: 5195.420000000013,
  },
  {
    date: '2022-12-07T00:00:00+00:00',
    open: 255195.42,
    openBalance: 255195.42,
    highest: 271668.17,
    lowest: 246019.29,
    close: 246019.29,
    closeBalance: 246019.29,
    profit: -9176.130000000005,
  },
  {
    date: '2022-12-08T00:00:00+00:00',
    open: 246019.29,
    openBalance: 246019.29,
    highest: 268681.1,
    lowest: 235426.11,
    close: 265428.54,
    closeBalance: 265428.54,
    profit: 19409.24999999997,
  },
  {
    date: '2022-12-09T00:00:00+00:00',
    open: 265428.54,
    openBalance: 265428.54,
    highest: 278118.35,
    lowest: 255724.6,
    close: 255724.6,
    closeBalance: 253488.75,
    profit: -11939.789999999979,
  },
  {
    date: '2022-12-12T00:00:00+00:00',
    open: 253488.75,
    openBalance: 253488.75,
    highest: 309771.81,
    lowest: 252941.13,
    close: 304051.33,
    closeBalance: 304051.33,
    profit: 50562.580000000016,
  },
  {
    date: '2022-12-13T00:00:00+00:00',
    open: 304051.33,
    openBalance: 304051.33,
    highest: 309349.25,
    lowest: 294429.26,
    close: 294429.26,
    closeBalance: 294429.26,
    profit: -9622.070000000007,
  },
  {
    date: '2022-12-14T00:00:00+00:00',
    open: 294429.26,
    openBalance: 294429.26,
    highest: 311640.43,
    lowest: 290770.43,
    close: 310296.2,
    closeBalance: 310296.2,
    profit: 15866.940000000002,
  },
  {
    date: '2022-12-16T00:00:00+00:00',
    open: 310296.2,
    openBalance: 310296.2,
    highest: 323848.23,
    lowest: 297488.23,
    close: 306542.52,
    closeBalance: 306542.52,
    profit: -3753.679999999993,
  },
  {
    date: '2022-12-19T00:00:00+00:00',
    open: 306542.52,
    openBalance: 306542.52,
    highest: 335788.4,
    lowest: 301038.4,
    close: 326789.05,
    closeBalance: 326789.05,
    profit: 20246.52999999997,
  },
  {
    date: '2022-12-20T00:00:00+00:00',
    open: 326789.05,
    openBalance: 326789.05,
    highest: 357848.11,
    lowest: 317098.11,
    close: 327718.11,
    closeBalance: 326678.11,
    profit: -110.94000000000233,
  },
  {
    date: '2022-12-21T00:00:00+00:00',
    open: 327718.11,
    openBalance: 326678.11,
    highest: 329468.51,
    lowest: 312714.89,
    close: 312714.89,
    closeBalance: 312714.89,
    profit: -13963.219999999972,
  },
  {
    date: '2022-12-22T00:00:00+00:00',
    open: 312714.89,
    openBalance: 312714.89,
    highest: 318151.37,
    lowest: 298204.57,
    close: 298204.57,
    closeBalance: 298204.57,
    profit: -14510.320000000007,
  },
]

const accountEquityBalance = [
  { x: '2024-03-29 13:00:15', y: [96980.81, 96980.81, 100000, 104030.3, 104030.3] },
  { x: '2024-03-29 18:00:15', y: [99980.81, 99980.81, 104000, 105030.3, 105030.3] },
]

export {
  tradingHistory, accountBalance, tradingVolume, accountEquity, longShort, instrumentAnalysis, accountEquityBalance,
}
