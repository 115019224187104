<template>
  <b-overlay
    :show="this.$parent.refreshing"
    :variant="skin"
    spinner-variant="primary"
  >
    <b-card no-body>
      <b-card-header>
        <b-card-title class="mb-25">
          Account equity
        </b-card-title>
        <b-card-text class="font-small-2 mr-25 mb-0">
          <b-form-radio-group
            v-model="type"
            button-variant="outline-primary"
            :options="typeOptions"
            buttons
            size="sm"
          />
        </b-card-text>
      </b-card-header>

      <b-card-body class="pb-0 position-relative">
        <vue-apex-charts
          type="candlestick"
          height="235px"
          :options="equityChart.chartOptions"
          :series="equityChart.series"
        />
        <b-overlay
          :show="samples"
          no-wrap
          :variant="skin"
          blur="2px"
          opacity=".95"
        >
          <template #overlay>
            <div class="text-center">
              You have to trade for at least three days.
            </div>
          </template>
        </b-overlay>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BFormRadioGroup, BOverlay, BCardText,
} from 'bootstrap-vue'
import { $themeColors } from '@themeConfig'
import VueApexCharts from 'vue-apexcharts'
import { get } from 'vuex-pathify'
import { formatNumber, formatDate } from '@/plugins/formaters'
import { accountEquity } from '../sample-data'

export default {
  components: {
    VueApexCharts,
    BCardText,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BFormRadioGroup,
    BOverlay,
  },
  props: {
    data: Array,
    currency: String,
    samples: Boolean,
  },
  data() {
    return {
      type: 'equity',
      typeOptions: [
        { text: 'Equity', value: 'equity' },
        { text: 'Balance', value: 'balance' },
      ],
    }
  },
  computed: {
    ...get('appConfig@layout', ['skin']),
    chartData() {
      const data = this.samples ? accountEquity : this.data
      return data.map(item => ({
        x: item.date,
        y: [
          this.type === 'equity' ? item.open : item.openBalance,
          item.highest,
          item.lowest,
          this.type === 'equity' ? item.close : item.closeBalance,
        ],
      }))
    },

    equityChart() {
      return {
        series: [
          {
            name: 'Equity',
            data: this.chartData,
          },
        ],
        chartOptions: {
          plotOptions: {
            candlestick: {
              colors: {
                upward: $themeColors.primary,
                downward: $themeColors.info,
              },
            },
          },
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'candlestick',
            dropShadow: {
              enabled: false,
              top: 18,
              left: 2,
              blur: 5,
              opacity: 0.2,
            },
          },
          grid: {
            borderColor: '#EBE9F1',
            padding: {
              top: -20,
              bottom: 5,
              left: 20,
            },
          },
          legend: {
            show: false,
          },
          xaxis: {
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
              formatter: val => formatDate(val),
            },
            type: 'numeric',
            tickPlacement: 'between',
            tickAmount: 6,
          },
          yaxis: {
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
              formatter: val => formatNumber(val, 'k'),
            },
          },
          tooltip: {
            x: { show: false },
          },
        },
      }
    },
  },
}
</script>
