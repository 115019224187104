<template>
  <b-overlay
    :show="this.$parent.refreshing"
    :variant="skin"
    spinner-variant="primary"
  >
    <b-card
      v-if="items"
      no-body
      class="card-statistics"
    >
      <b-card-header>
        <b-card-title>Statistics</b-card-title>
      </b-card-header>
      <b-card-body class="statistics-body">
        <b-row>
          <b-col
            v-for="item in items"
            :key="item.name"
            xxl="3"
            xl="6"
            lg="3"
            sm="6"
            cols="12"
          >
            <b-media
              no-body
              class="mb-2 mb-xxl-0"
            >
              <b-media-aside
                class="mr-2"
              >
                <b-avatar
                  size="48"
                  :variant="item.color"
                  :class="item.customClass"
                >
                  <feather-icon
                    size="24"
                    :icon="item.icon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ item.title }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ item.subtitle }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BOverlay,
} from 'bootstrap-vue'
import { get } from 'vuex-pathify'
import { formatCurrency } from '@/plugins/formaters'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BOverlay,
  },
  props: {
    data: Object,
    currency: String,
  },
  data() {
    return {
      items: [
        {
          name: 'average_win',
          icon: 'DollarSignIcon',
          title: this.data.average_win ? formatCurrency(this.data.average_win, this.currency) : '-',
          subtitle: 'Average Win',
          color: 'info',
          customClass: 'badge-light-success',
        },
        {
          name: 'average_loss',
          icon: 'DollarSignIcon',
          title: this.data.average_loss ? formatCurrency(this.data.average_loss, this.currency) : '-',
          subtitle: 'Average Loss',
          color: '',
          customClass: 'badge-light-danger',
        },
        {
          name: 'win_ratio',
          icon: 'PercentIcon',
          title: this.data.win_ratio ? `${Math.round(this.data.win_ratio)}%` : '-',
          subtitle: 'Win Ratio',
          color: 'success',
          customClass: 'badge-light-primary',
        },
        {
          name: 'risk_reward',
          icon: 'TrendingUpIcon',
          title: this.data.risk_reward ? Math.round(this.data.risk_reward * 100) / 100 : '-',
          subtitle: 'Risk Reward',
          color: 'danger',
          customClass: 'badge-light-primary',
        },
      ],
    }
  },
  computed: {
    ...get('appConfig@layout', ['skin']),
  },
}
</script>
<style scoped lang="scss">

.statistics-body{
  padding-top: 0!important;
}
</style>
