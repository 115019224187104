<template>
  <gql-table
    ref="newsToTradeGqlTable"
    title="Restricted news trading"
    :fields="fields"
    :query="query"
    query-type="newsToTrades"
  >
    <template #cell(symbol)="data">
      {{ data.item.trade.symbol }}
    </template>

    <template #cell(type)="data">
      <div
        v-if="data.item.trade.type === 0"
        class="color-info"
      >
        Buy
      </div>
      <div
        v-else-if="data.item.trade.type === 1"
        class="color-warning"
      >
        Sell
      </div>
    </template>

    <template #cell(volume)="data">
      {{ formatNumber(data.item.trade.volume) }}
    </template>

    <template #cell(profit)="data">
      {{ formatNumber(data.item.trade.profit) }}
    </template>

    <template #cell(swap)="data">
      {{ formatNumber(data.item.trade.swap) }}
    </template>

    <template #cell(commission)="data">
      {{ formatNumber(data.item.trade.commission) }}
    </template>

    <template #cell(close_datetime)="data">
      {{ formatDateTime(data.item.trade.close_datetime) }}
    </template>

    <template #cell(open_datetime)="data">
      {{ formatDateTime(data.item.trade.open_datetime) }}
    </template>

    <template #cell(title)="data">
      {{ data.item.news.title }} | {{ data.item.news.country }}
    </template>

    <template #cell(date)="data">
      {{ formatDateTime(data.item.news.date) }}
    </template>

    <template #cell(impact)="data">
      <b-badge :variant="impactVariants[data.item.news.impact]">
        {{ data.item.news.impact }}
      </b-badge>
    </template>
  </gql-table>
</template>
<script>
import { BBadge } from 'bootstrap-vue'
import GqlTable from '@/components/ui/gqltables/GqlTable.vue'
import { IMPACT_VARIANTS } from '@/enums/news'
import { formatDateTime, formatNumber } from '@/plugins/formaters'

const query = [
  {
    news: {
      fields: [
        'id',
        'title',
        'country',
        'date',
        'impact',
        'forecast',
        'previous',
        'restricted',
      ],
    },
  },
  {
    trade: {
      fields: [
        'id',
        'symbol',
        'type',
        'open_datetime',
        'close_datetime',
        'volume',
        'profit',
        'swap',
        'commission',
      ],
    },
  },
]
export default {
  components: { GqlTable, BBadge },
  props: {
    accountId: String,
  },
  data() {
    return {
      impactVariants: IMPACT_VARIANTS,
      query,
    }
  },
  computed: {
    fields() {
      return [
        {
          name: 'symbol', label: 'Symbol',
        },
        { name: 'type', label: 'Type' },
        { name: 'open_datetime', label: 'Open date' },
        { name: 'close_datetime', label: 'Closed date' },
        { name: 'volume', label: 'Lots' },
        { name: 'swap', label: 'Swap' },
        { name: 'commission', label: 'Commission' },
        { name: 'profit', label: 'Profit' },
        { name: 'title', label: 'News title' },
        { name: 'date', label: 'Date' },
        { name: 'impact', label: 'Impact' },

        {
          name: 'account',
          label: 'Account',
          filterable: {
            type: 'eq', visible: false,
          },
          default: this.accountId,
          visible: false,
        },
      ]
    },
  },
  methods: { formatNumber, formatDateTime },
}
</script>
