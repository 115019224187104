<template>
  <b-card>
    <b-card-header>
      <b-card-title class="mb-25">
        Account relative balance equity
      </b-card-title>
    </b-card-header>
    <b-card-body class="pb-0 position-relative">
      <vue-apex-charts
        type="boxPlot"
        height="235px"
        :options="chart.chartOptions"
        :series="chart.series"
      />
      <b-overlay
        :show="samples"
        no-wrap
        :variant="skin"
        blur="2px"
        opacity=".95"
      >
        <template #overlay>
          <div class="text-center">
            No relative balance record was found
          </div>
        </template>
      </b-overlay>
    </b-card-body>
  </b-card>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import {
  BCardBody, BOverlay, BCard, BCardTitle, BCardHeader,
} from 'bootstrap-vue'
import { get } from 'vuex-pathify'
import { $themeColors } from '@themeConfig'
import { formatDate, formatNumber } from '@/plugins/formaters'
import { accountEquityBalance } from '../sample-data'

export default {
  components: {
    BCardHeader,
    BCardTitle,
    BOverlay,
    BCardBody,
    BCard,
    VueApexCharts,
  },
  props: {
    data: Array,
    samples: Boolean,
  },
  computed: {
    ...get('appConfig@layout', ['skin']),
    series() {
      return this.samples ? accountEquityBalance : this.data.map(item => ({ x: item.TIMESTAMP, y: [item.EQUITY_LOWEST, item.EQUITY_LOWEST, item.BALANCE, item.EQUITY_HIGHEST, item.EQUITY_HIGHEST] }))
    },
    chart() {
      return {
        series: [
          {
            type: 'boxPlot',
            data: this.series,
          },
        ],
        chartOptions: {
          toolbar: { show: false },
          zoom: { enabled: false },
          chart: {
            type: 'boxPlot',
            dropShadow: {
              enabled: false,
              top: 18,
              left: 2,
              blur: 5,
              opacity: 0.2,
            },
          },
          plotOptions: {
            boxPlot: {
              colors: {
                upper: $themeColors.primary,
                lower: $themeColors.info,
              },
            },
          },
          grid: {
            borderColor: '#EBE9F1',
            padding: {
              top: -20,
              bottom: 5,
              left: 20,
            },
          },
          legend: {
            show: false,
          },
          xaxis: {
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
              formatter: val => formatDate(val),
              hideOverlappingLabels: true,
              showDuplicates: false,
            },
            type: 'numeric',
            tickPlacement: 'between',
            tickAmount: 6,
          },
          yaxis: {
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
              formatter: val => formatNumber(val, 'k'),
            },
          },
          tooltip: {
            x: { show: false },
            custom({
              seriesIndex, dataPointIndex, w,
            }) {
              return (
                `<div class="p-50"><strong>${w.globals.initialSeries[seriesIndex].data[dataPointIndex].x}</strong><br><span>High: ${w.globals.initialSeries[seriesIndex].data[dataPointIndex].y[4]}</span><br><span>Balance: ${w.globals.initialSeries[seriesIndex].data[dataPointIndex].y[2]}</span><br><span>Low: ${w.globals.initialSeries[seriesIndex].data[dataPointIndex].y[0]}</span></div>`
              )
            },
          },
        },

      }
    },
  },
}
</script>
