<template>
  <b-card
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <b-card-title>Account data</b-card-title>
    </b-card-header>

    <b-card-body>
      <div
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              variant="light-success"
            >
              <calendar-icon
                size="18"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              Start
            </h6>
            <small>Start of the trading period</small>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder"
        >
          {{ data.start_datetime?formatDate(data.start_datetime):'-' }}
        </div>
      </div>
      <div
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              variant="light-danger"
            >
              <calendar-icon
                size="18"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              End
            </h6>
            <small>End of the trading period</small>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder"
        >
          {{ data.end_datetime?formatDate(data.end_datetime):'-' }}
        </div>
      </div>
      <div
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              variant="light-primary"
            >
              <dollar-sign-icon
                size="18"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              Initial balance
            </h6>
            <small>Size of account</small>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder"
        >
          {{ formatCurrency(data.deposit, data.currency.label) }}
        </div>
      </div>
      <div
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              variant="light-warning"
            >
              <credit-card-icon
                size="18"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              Type of platform
            </h6>
            <small>Name of trading platform</small>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder"
        >
          {{ data.platform.id }} ({{ data.execution_type }})
        </div>
      </div>
      <div
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              :variant="`light-${skin==='light'?'secondary':'light'}`"
            >
              <database-icon
                size="18"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              Server
            </h6>
            <small>MT4 server provider</small>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder"
        >
          {{ data.server.name }}
        </div>
      </div>
      <div
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              variant="light-primary"
            >
              <dollar-sign-icon
                size="18"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              Withdrawal datetime
            </h6>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder"
        >
          {{ formatDateTime(data.withdrawal_datetime) }}
        </div>
      </div>
      <div
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              variant="light-primary"
            >
              <dollar-sign-icon
                size="18"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              Next withdrawal datetime
            </h6>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder"
        >
          {{ formatDateTime(data.next_withdrawal_datetime) }}
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BAvatar, BMedia, BMediaAside, BMediaBody,
} from 'bootstrap-vue'
import {
  CalendarIcon, DollarSignIcon, CreditCardIcon, DatabaseIcon,
} from 'vue-feather-icons'
import { get } from 'vuex-pathify'
import { formatCurrency, formatDate, formatDateTime } from '@/plugins/formaters'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BAvatar,
    BMedia,
    BMediaBody,
    BMediaAside,
    CalendarIcon,
    DollarSignIcon,
    CreditCardIcon,
    DatabaseIcon,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      platform: 'MT4',
    }
  },
  computed: {
    ...get('appConfig@layout', ['skin']),
  },
  methods: {
    formatDateTime,
    formatCurrency,
    formatDate,
  },
}
</script>
