<template>
  <div id="trading-acc-history">
    <b-overlay
      :show="this.$parent.refreshing"
      :variant="skin"
      spinner-variant="primary"
    >
      <b-card
        no-body
      >
        <b-card-header class="justify-content-between">
          <b-card-title>Trading account history</b-card-title>
          <b-form-group
            label="Risk limit %"
            class="ml-md-auto"
            label-align-sm="left"
            label-size="sm"
          >
            <b-form-input
              v-model.number="riskLimit"
              type="number"
              class="form-control-sm"
            />
          </b-form-group>
          <b-form-group
            label=""
            label-cols-sm="2"
            label-align-sm="left"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-card-header>
        <div class="position-relative">

          <b-table
            striped
            hover
            responsive
            small
            class="position-relative"
            :per-page="perPage"
            :current-page="currentPage"
            :items="tableData"
            :fields="fields"
            :filter="filter"
            :filter-included-fields="filterOn"
            :sort-by="'close_datetime'"
            :sort-desc="true"
            @filtered="onFiltered"
          >

            <template #cell(type)="data">
              <div
                v-if="data.value === 0"
                class="color-info"
              >
                Buy
              </div>
              <div
                v-else-if="data.value === 1"
                class="color-warning"
              >
                Sell
              </div>
            </template>
            <template #cell(profit)="data">
              <div
                v-if="riskLimit !== 0 && data.value <= account.deposit * (riskLimit/100) * -1"
                class="color-warning"
              >
                {{ formatCurrency(data.value, currency) }}
              </div>
              <span v-else>
                {{ formatCurrency(data.value, currency) }}
              </span>
            </template>
            <template #cell(swap)="data">
              {{ formatCurrency(data.value, currency) }}
            </template>
            <template #cell(commission)="data">
              {{ formatCurrency(data.value, currency) }}
            </template>
            <template #cell(close_datetime)="data">
              {{ formatDateTime(data.value) }}
            </template>
            <template #cell(open_datetime)="data">
              {{ formatDateTime(data.value) }}
            </template>
          </b-table>

          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

            <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                inline
                :options="pageOptions"
              />
            </b-form-group>

            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
          <b-overlay
            :show="samples"
            no-wrap
            :variant="skin"
            blur="2px"
            opacity=".95"
          >
            <template #overlay>
              <div class="text-center">
                You do not have any finished trades yet.
              </div>
            </template>
          </b-overlay>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BButton,
  BCardBody,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BOverlay,
  BPagination,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
} from 'bootstrap-vue'
import { get } from 'vuex-pathify'
import { tradingHistory } from '../sample-data'

import { formatCurrency, formatDateTime } from '@/plugins/formaters'

export default {
  components: {
    BCard,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BOverlay,
    BCardHeader,
    BCardTitle,
  },
  props: {
    data: Array,
    currency: String,
    samples: Boolean,
    account: Object,
  },
  data() {
    return {
      riskLimit: 0,
      perPage: 10,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      filter: null,
      filterOn: [],
      fields: [
        {
          key: 'symbol', label: 'Symbol', sortable: true, width: 250,
        },
        { key: 'type', label: 'Type', sortable: true },
        { key: 'open_datetime', label: 'Open date', sortable: true },
        { key: 'open_price', label: 'Open price', sortable: true },
        { key: 'close_datetime', label: 'Closed date', sortable: true },
        { key: 'close_price', label: 'Closed price', sortable: true },
        { key: 'volume', label: 'Lots', sortable: true },
        { key: 'take_profit', label: 'Take profit', sortable: true },
        { key: 'stop_loss', label: 'Stop loss', sortable: true },
        { key: 'swap', label: 'Swap', sortable: true },
        { key: 'commission', label: 'Commission', sortable: true },
        { key: 'profit', label: 'Profit', sortable: true },
      ],
    }
  },
  computed: {
    ...get('appConfig@layout', ['skin']),
    tableData() {
      return this.samples ? tradingHistory : this.data
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    this.totalRows = this.data.length
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    formatDateTime,
    formatCurrency,
  },
}
</script>

<style lang="scss">
#trading-acc-history {
  .table {
    min-width: 1130px;
    tr {
      padding: 0 10px;

      td {
        padding: 5px 5px 5px 20px;
      }

      th {
        padding: 5px 5px 5px 20px;
        &:last-child{
          background-position: right 1.5vw center!important;
        }
      }

      &:first-child {
        padding-left: 20px;
      }

      &:last-child {
        margin-right: 20px;
      }

      th:last-child{
        margin-right: 20px;
      }
    }
  }

  thead {
    padding-right: 20px;
  }
}

.color-warning, .color-info {
  border-radius: 25px;
  padding: 2px 10px;
  font-weight: bold;
  text-align: center;
}

.color-warning {
  background: rgba($danger, 0.18);
  color: $danger;
}

.color-info {
  background: rgba($primary, 0.1);
  color: $primary;
}
</style>
