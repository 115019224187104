<template>
  <b-overlay
    :show="this.$parent.refreshing"
    :variant="skin"
    spinner-variant="primary"
  >
    <b-card no-body>
      <b-card-header class="align-items-baseline">
        <b-card-title>
          <div
            id="popover-account-balance"
            variant="unstyled"
            class="w-100 text-center px-0"
          >
            <h4 class="vh-center">Account balance
              <feather-icon
                class="m-05 my-0 text-white"
                size="18"
                icon="InfoIcon"
              />
            </h4>
          </div>
          <b-popover
            target="popover-account-balance"
            variant="warning"
            triggers="hover"
            placement="top"
          >
            <template #title>
              <div class="w-100 vh-center">
                <span class="text-center mx-auto"> Account balance </span>
              </div>
            </template>
            <p class="text-center">
              The chart data auto-updates based on the number of trades in your trading history. {{ warning }}</p>
          </b-popover>
        </b-card-title>
        <p class="font-medium-2 pl-0 pb-0 p-05 font-weight-bold text-header">Profit: <span
          class="font-weight-bolder"
          :class="`text-${skin==='light'?'darker':'white'}`"
        >{{ (`${Math.round((((account.balance/account.deposit)*100)-100)*100)}`)/100 }}%</span></p>
      </b-card-header>

      <b-card-body class="pb-0 position-relative">

        <vue-apex-charts
          type="line"
          height="300px"
          class="balance-chart"
          :options="balanceChart.chartOptions"
          :series="balanceChart.series"
        />
        <b-overlay
          :show="samples"
          no-wrap
          :variant="skin"
          blur="2px"
          opacity=".95"
        >
          <template #overlay>
            <div class="text-center">
              You do not have any finished trades yet.
            </div>
          </template>
        </b-overlay>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BOverlay, VBPopover, BPopover,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { get } from 'vuex-pathify'
import { formatCurrency, formatNumber } from '@/plugins/formaters'
import { accountBalance } from '../sample-data'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BOverlay,
    BPopover,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    data: Array,
    currency: String,
    samples: Boolean,
    account: Object,
  },
  data() {
    return {
      warning: null,
    }
  },
  computed: {
    ...get('appConfig@layout', ['skin']),
    chartData() {
      return this.samples ? accountBalance : this.limitData(this.data)
    },
    balanceChart() {
      return {
        series: [
          {
            name: 'Balance',
            data: Object.values(this.chartData),
          },
        ],
        chartOptions: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            dropShadow: {
              enabled: false,
              top: 18,
              left: 2,
              blur: 5,
              opacity: 0.2,
            },
            offsetX: -10,
          },
          stroke: {
            curve: 'straight',
            width: 5,
          },
          grid: {
            borderColor: '#EBE9F1',
            padding: {
              top: -20,
              bottom: 5,
              left: 20,
            },
          },
          legend: {
            show: false,
          },
          colors: [$themeColors.primary],
          markers: {
            size: 2,
            colors: 'grey',
            hover: {
              size: 6,
            },
          },
          xaxis: {
            labels: {
              hideOverlappingLabels: true,
              offsetY: 5,
              trim: false,
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
            },
            axisTicks: {
              show: false,
            },
            categories: Object.keys(this.chartData).map(item => item),
            axisBorder: {
              show: false,
            },
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
              formatter: val => formatNumber(val, 'k'),
            },
          },
          tooltip: {
            x: { show: true },
            y: {
              formatter: value => formatCurrency(value, this.currency),
            },
          },
        },
      }
    },
  },
  methods: {
    limitData(data) {
      if (data.length > 2000) {
        return this.groupAverage(data, 100)
      } if (data.length > 1000) {
        return this.groupAverage(data, 50)
      } if (data.length > 400) {
        return this.groupAverage(data, 20)
      } if (data.length > 200) {
        return this.groupAverage(data, 10)
      } if (data.length > 100) {
        return this.groupAverage(data, 5)
      }
      const result = {}
      for (let i = 0; i < data.length; i++) {
        result[i] = data[i]
      }

      return result
    },
    groupAverage(arr, n) {
      this.warning = `You will always see your last 10 trades without being averaged with the total. Currently you see an average of ${n}(n) trades applied to your balance + 10 last trades.`
      arr.shift()
      const result = {
        0: this.account.deposit,
      }
      for (let i = 0; i < arr.length;) {
        if (i < (arr.length - 11)) {
          let sum = 0
          for (let j = 0; j < n; j++) {
            sum += +arr[i++] || 0
            if (i > (arr.length - 10)) { break }
          }
          result[i] = sum / n
        } else {
          result[i] = arr[i++]
        }
      }
      return result
    },
  },
}
</script>

<style lang="scss" scoped>
.balance-chart::v-deep {
  .apexcharts-xaxis-texts-g text[id^='SvgjsText']:not(:first-of-type) {
    display: none;
  }

  @media (max-width: map-get($grid-breakpoints, 'sm')) {
    .apexcharts-xaxis-texts-g text[id^='SvgjsText']:nth-of-type(5n) {
      display: revert;
    }
  }
  @media (min-width: map-get($grid-breakpoints, 'sm')) and (max-width: map-get($grid-breakpoints, 'md')) {
    .apexcharts-xaxis-texts-g text[id^='SvgjsText']:nth-of-type(3n) {
      display: revert;
    }
  }
  @media (min-width: map-get($grid-breakpoints, 'lg')) {
    .apexcharts-xaxis-texts-g text[id^='SvgjsText']:nth-of-type(2n) {
      display: revert;
    }
  }
}

</style>
