<template>
  <b-card>
    <b-card-header class="justify-content-between">
      <b-card-title>Daily equity extremes</b-card-title>

      <b-form-group
        label="Profit cap %"
        class="ml-md-auto mx-50"
        label-align-sm="left"
        label-size="sm"
      >
        <b-form-input
          v-model.number="ddCap"
          type="number"
          class="form-control-sm"
        />
      </b-form-group>
      <b-form-group
        label="Drawdown limit %"
        class="mx-50"
        label-size="sm"
      >
        <b-form-input
          v-model.number="ddLimit"
          type="number"
          class="form-control-sm"
        />
      </b-form-group>
    </b-card-header>
    <div v-if="items.length > 0">
      <b-table
        striped
        hover
        responsive
        small
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :fields="fields"
        :items="items"
      >

        <template #cell(date)="data">
          {{ data.item.date.substring(0,10) }}

          <span
            v-clipboard:copy="getEquityMessage(data.item)"
            v-clipboard:success="onCopySuccess"
            v-clipboard:error="onCopyError"
            v-b-tooltip.hover.right="'Copy the lowest equity explanation'"
            class="font-weight-bolder"
          >
            <feather-icon
              class="mr-1"
              icon="CopyIcon"
              size="15"
            />
          </span>
          <span
            v-b-tooltip.hover.right="'Edit equity record'"
            class="font-weight-bolder"
            @click="onEditEquity(data.item)"
          >
            <feather-icon
              class="mr-1"
              icon="EditIcon"
              size="15"
            />
          </span>
        </template>

        <template #cell(closed_profit)="data">
          <b-badge :variant="data.item.close_balance - data.item.open_balance < 0 ? 'light-danger' : 'light-success'">
            {{ ddCap !== 0 && data.item.close_balance - data.item.open_balance > deposit * (ddCap/100) ? formatNumber(deposit * (ddCap/100)) : formatNumber(data.item.close_balance - data.item.open_balance) }}
          </b-badge>
        </template>

        <template #cell(closed_profit_per)="data">
          {{ formatNumber(percentProfit(data.item.close_balance - data.item.open_balance, data.item.date)) }} %
        </template>

        <template #cell(volume)="data">
          {{ sumLotsTrades(data.item.date.substring(0,10)) }}
        </template>

        <template #cell(highest_datetime)="data">
          {{ utcDate(data.item.highest_datetime) }}
        </template>

        <template #cell(lowest_datetime)="data">
          {{ utcDate(data.item.lowest_datetime) }}
        </template>

        <template #cell(checked_at)="data">
          {{ utcDate(data.item.checked_at) }}
        </template>

        <template #cell(checker_lowest_datetime)="data">
          {{ utcDate(data.item.checker_lowest_datetime) }}
        </template>

        <template #cell(failed)="data">
          <b-badge
            v-if="data.item.failed"
            variant="light-danger"
          >
            Failed
          </b-badge>
        </template>

        <template #cell(download)="data">
          <div
            v-if="data.item.failed"
          >
            <b-button
              variant="outline-primary"
              class="m-50"
              @click="downloadReport(data.item.id, 'csv')"
            >
              CSV
            </b-button>
            <b-button
              variant="outline-primary"
              class="m-50"
              @click="downloadReport(data.item.id, 'txt')"
            >
              TXT
            </b-button>
          </div>
        </template>

        <template #cell(custom_dd_limit)="data">
          <b-badge
            v-if="tableItems[data.item.id] && tableItems[data.item.id].failed"
            variant="light-danger"
          >
            {{ formatCurrency(tableItems[data.item.id].loss * -1, currency) }}
            Failed
          </b-badge>
          <b-badge
            v-else-if="tableItems[data.item.id] && !tableItems[data.item.id].failed"
            variant="light-primary"
          >
            {{ formatCurrency(tableItems[data.item.id].loss * -1, currency) }}
            Pass
          </b-badge>
        </template>
      </b-table>

      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </div>
    <div v-else>
      No equity was captured yet
    </div>
    <b-modal
      id="equityEditModal"
      title="Edit equity"
      :no-close-on-backdrop="true"
      hide-footer
      size="xl"
    >
      <equity-history-edit-form
        :equity-history="equityEdit"
        @completed="onEquityModalClose"
      />
    </b-modal>
  </b-card>
</template>
<script>
import {
  BBadge, BButton, BCard, BCardHeader, BCardTitle, BModal, BPagination, BTable, BFormInput, BFormGroup, VBTooltip,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import copyToClipboard from '@/mixins/copyToClipboard'
import {
  formatCurrency, formatDate, formatDateTime, formatNumber, utcDate, utcToMtDate,
} from '@/plugins/formaters'
import AccountApi from '@/api/account'
import EquityHistoryEditForm from '@/components/forms/account/EquityHistoryEditForm.vue'

export default {
  components: {
    EquityHistoryEditForm,
    BFormInput,
    BFormGroup,
    BModal,
    BCard,
    BTable,
    BPagination,
    BCardHeader,
    BCardTitle,
    BBadge,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [copyToClipboard],
  props: {
    items: Array,
    accountId: [Number, String],
    currency: String,
    isBalanceBased: Boolean,
    deposit: Number,
    ruleDrawdown: String,
    balance: Number,
    withdrawalDatetime: [String, Object, null],
    tradingHistory: [Array, null],
  },
  data() {
    return {
      ddLimit: 2.5,
      ddCap: 0,
      perPage: 10,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      fields: [
        { key: 'date', label: 'Day', sortable: true },
        { key: 'lowest', label: 'Lowest equity', sortable: true },
        { key: 'lowest_datetime', label: 'LE time', sortable: true },
        { key: 'highest', label: 'Highest equity', sortable: true },
        { key: 'highest_datetime', label: 'HE time', sortable: true },
        { key: 'open', label: 'Open equity', sortable: true },
        { key: 'open_balance', label: 'Open balance', sortable: true },
        { key: 'close', label: 'Close equity', sortable: true },
        { key: 'close_balance', label: 'Close balance', sortable: true },
        { key: 'closed_profit', label: 'Closed profit', sortable: false },
        { key: 'closed_profit_per', label: '%', sortable: false },
        { key: 'volume', label: 'V|T', sortable: false },
        { key: 'checked_at', label: 'AVENTUS AT', sortable: true },
        { key: 'checker_lowest', label: 'AVENTUS LE', sortable: true },
        { key: 'checker_lowest_datetime', label: 'AVENTUS LE time', sortable: true },
        { key: 'failed', label: 'AVENTUS Failed', sortable: true },
        { key: 'download', label: 'AVENTUS Report', sortable: false },
        { key: 'custom_dd_limit', label: 'Custom dd limit', sortable: false },
      ],
      equityEdit: null,
    }
  },
  computed: {
    tableItems() {
      if (!this.items || this.items.length === 0 || !['initial-daily-simple', 'initial-daily', 'relative'].includes(this.ruleDrawdown)) return {}

      const items = {}
      this.items.forEach(item => {
        let oValue
        if (this.ruleDrawdown === 'initial-daily') {
          oValue = item.open_balance > item.open ? item.open_balance : item.open
        } else {
          oValue = item.open_balance
        }

        const loss = oValue - item.lowest
        const maxLoss = this.deposit * (this.ddLimit / 100)
        items[item.id] = { loss, failed: loss > maxLoss }
      })
      return items
    },
  },
  mounted() {
    this.totalRows = this.items.length
  },
  methods: {
    formatNumber,
    formatDateTime,
    formatCurrency,
    formatDate,
    utcDate,
    utcToMtDate,
    downloadReport(equityId, type) {
      this.showLoader(true)
      AccountApi.getAventusReport(equityId, type, this.accountId)
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Request failed',
              icon: 'WarningIcon',
              text: err.statusText,
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.showLoader(false)
        })
    },
    getEquityMessage(item) {
      const open = this.isBalanceBased || item.open_balance > item.open ? item.open_balance : item.open
      return `Your account opened at ${formatCurrency(open, this.currency)}. \nYour lowest equity point was ${formatCurrency(item.lowest, this.currency)}, which resulted in a daily loss of ${formatCurrency(open - item.lowest, this.currency)}.`
    },
    onEditEquity(item) {
      this.equityEdit = item
      this.$bvModal.show('equityEditModal')
    },
    onEquityModalClose() {
      this.$bvModal.hide('equityEditModal')
      this.equityEdit = null
      this.$parent.load()
    },
    percentProfit(amount, d) {
      if (amount <= 0 || (this.withdrawalDatetime !== null && utcToMtDate(this.withdrawalDatetime) >= new Date(d))) return 0

      const mtd = utcToMtDate(new Date().toISOString())
      const sum = this.items.reduce((accumulator, object) => {
        if (object.close_balance - object.open_balance > 0 && (this.withdrawalDatetime === null || utcToMtDate(this.withdrawalDatetime) < new Date(object.date)) && object.date !== mtd.toISOString().split('T')[0]) {
          accumulator += object.close_balance - object.open_balance
        }
        if (object.date === mtd.toISOString().split('T')[0]) {
          accumulator += this.balance - object.open_balance
        }
        return accumulator
      }, 0)

      if (sum === 0) {
        return 0
      }
      return (amount / sum) * 100
    },
    sumLotsTrades(d) {
      if (!this.tradingHistory) return ''

      const sumVolume = this.tradingHistory.reduce((accumulator, object) => {
        if (utcToMtDate(object.open_datetime).toISOString().split('T')[0] === d) {
          accumulator += object.volume
        }
        return accumulator
      }, 0)

      const filteredTrades = this.tradingHistory.filter(object => utcToMtDate(object.open_datetime).toISOString().split('T')[0] === d)

      return `${formatNumber(sumVolume)} | ${filteredTrades.length}`
    },
  },
}
</script>
