<template>
  <div class="goals-overview">
    <b-overlay
      :show="this.$parent.refreshing || refreshing"
      :variant="skin"
      spinner-variant="primary"
    >
      <b-card>
        <b-card-header>
          <b-card-title>Goals overview</b-card-title>
          <span
            class="v-center text-primary font-weight-bold loading-page cursor-pointer"
            :class="this.$parent.refreshing?'loading':''"
            @click="onRefreshClick"
          >
            <feather-icon
              icon="RefreshCwIcon"
              size="20"
            />
          </span>
        </b-card-header>
        <b-card-body>
          <b-row class="no-gutters m-05 mt-0 mb-2">
            <b-col
              v-if="state === 'failed'"
              sm="12"
            >
              <div
                class="m-05 py-2 px-1 border-radius-7"
                :class="`bg-${skin==='dark'?'darker':'light'}`"
              >
                <p class="font-weight-bold text-danger font-small-4 mb-0 text-center">
                  You have failed to adhere to one of the Trading Objectives below and your account is not eligible to continue trading.</p>
              </div>
            </b-col>
            <b-col
              v-if="state === 'completed'"
              sm="12"
            >

              <div class="m-05 e8-border-gradient">
                <div
                  class="py-2 px-1 border-radius-12"
                  :class="`bg-${skin==='dark'?'darker':'light'}`"
                >
                  <p class="text-primary font-medium-5 text-center font-weight-bolder">
                    Congratulations
                  </p>
                  <p
                    class="font-small-4 text-center font-weight-bold mb-0"
                    :class="`text-${skin==='light'?'dark':'white'}`"
                  >
                    You have successfully passed this phase of your evaluation! You will receive an e-mail with confirmation that your new account has ben set up to continue to the next phase.
                  </p>
                </div>
              </div>
            </b-col>
            <b-col
              v-if="account.pending_close"
              sm="12"
            >
              <div class="m-05 e8-border-gradient">
                <div
                  class="py-2 px-1 border-radius-12"
                  :class="`bg-${skin==='dark'?'darker':'light'}`"
                >
                  <p
                    class="font-small-4 text-center font-weight-bold mb-0"
                    :class="`text-${skin==='light'?'dark':'white'}`"
                  >
                    You are currently passing all the trading objectives but you still have running trades. Please close all your trades to finish account check.
                  </p>
                </div>
              </div>
            </b-col>

            <b-col
              v-for="(objective, key, index) in objectives"
              :key="index"
              lg="6"
            >
              <div
                v-if="data[key] !== undefined"
                class="m-05 py-2 px-1 border-radius-7"
                :class="`bg-${skin==='dark'?'darker':'light'}`"
              >
                <div
                  class="mr-auto font-weight-bold v-center justify-content-between"
                >
                  <b-button
                    :id="`popover-goals-overview-${objective.id}`"
                    :title="objective.name"
                    variant="unstyled"
                    class="button-transparent pl-0 pr-0"
                  >
                    <span
                      class="border-bottom"
                      :class="`text-${skin==='light'?'dark':'white'}`"
                    >
                      {{ objective.name }}
                    </span>
                    <feather-icon
                      size="18"
                      icon="InfoIcon"
                      class="ml-05"
                      :class="`text-${skin==='light'?'dark':'white'}`"
                    />
                  </b-button>
                  <b-popover
                    :target="`popover-goals-overview-${objective.id}`"
                    variant="warning"
                    triggers="hover"
                    placement="top"
                  >
                    <template #title>
                      <span>{{ objective.name }}</span>
                    </template>
                    <span>{{ objective.text }}</span>
                  </b-popover>
                  <div class="d-none d-xl-block">
                    <div v-if="objective.status === 'passing'">
                      <b-badge
                        v-if="data[key].current>=data[key].target_value"
                        variant="danger"
                      >
                        Failed
                      </b-badge>
                      <b-badge
                        v-else
                        variant="success"
                      >
                        {{ state !== 'active' ? 'Passed' : 'Passing' }}
                      </b-badge>
                    </div>
                    <div v-if="objective.status === 'pending'">
                      <b-badge
                        v-if="state === 'active' && data[key].current<data[key].target_value"
                        variant="warning"
                      >
                        Pending
                      </b-badge>
                      <b-badge
                        v-else-if="state==='failed' && data[key].current<data[key].target_value"
                        variant="danger"
                      >
                        Failed
                      </b-badge>
                      <b-badge
                        v-else
                        variant="success"
                      >
                        {{ state !== 'active' ? 'Passed' : 'Passing' }}
                      </b-badge>
                    </div>
                  </div>
                </div>

                <div class="v-center justify-content-between m-05 mx-0">
                  <span>{{ objective.status === 'pending' ? 'Minimum' : 'Maximum' }}:</span>
                  <div>
                    {{
                      objective.type === 'currency' ? formatCurrency(data[key].target_value, currency) : `${data[key].target_value}`
                    }}
                    <span
                      v-if="objective.type != 'currency'"
                    >
                      {{ data[key].target_value === 1 ? 'Day' : 'Days' }}
                    </span>
                  </div>
                </div>
                <div
                  class="v-center ml-auto mr-auto text-center justify-content-between"
                >
                  <span>Current result:</span>
                  <div>
                    <div v-if="objective.status === 'passing'">
                      <b-badge
                        v-if="data[key].current>=data[key].target_value"
                        variant="light-danger"
                        class="font-medium-1"
                      >
                        <div>
                          {{
                            objective.type === 'currency' ? formatCurrency(data[key].current, currency) : `${data[key].current}`
                          }}
                          <span
                            v-if="objective.type != 'currency'"
                          >
                            {{ data[key].target_value === 1 ? 'Day' : 'Days' }}
                          </span>
                        </div>
                      </b-badge>
                      <b-badge
                        v-else
                        variant="light-secondary"
                        class="font-medium-1"
                      >
                        <div>
                          {{
                            objective.type === 'currency' ? formatCurrency(data[key].current, currency) : `${data[key].current}`
                          }}
                          <span
                            v-if="objective.type != 'currency'"
                          >
                            {{ data[key].target_value === 1 ? 'Day' : 'Days' }}
                          </span>
                        </div>
                      </b-badge>
                    </div>
                    <div v-if="objective.status === 'pending'">
                      <b-badge
                        v-if="state === 'active' && data[key].current<data[key].target_value"
                        variant="light-warning"
                        class="font-medium-1"
                      >
                        <div>
                          {{
                            objective.type === 'currency' ? formatCurrency(data[key].current, currency) : `${data[key].current}`
                          }}
                          <span
                            v-if="objective.type != 'currency'"
                          >
                            {{ data[key].target_value === 1 ? 'Day' : 'Days' }}
                          </span>
                        </div>
                      </b-badge>
                      <b-badge
                        v-else-if="state==='failed' && data[key].current<data[key].target_value"
                        variant="light-danger"
                        class="font-medium-1"
                      >
                        <div>
                          {{
                            objective.type === 'currency' ? formatCurrency(data[key].current, currency) : `${data[key].current}`
                          }}
                          <span
                            v-if="objective.type != 'currency'"
                          >
                            {{ data[key].target_value === 1 ? 'Day' : 'Days' }}
                          </span>
                        </div>
                      </b-badge>
                      <b-badge
                        v-else
                        variant="light-success"
                        class="font-medium-1"
                      >
                        <div>
                          {{
                            objective.type === 'currency' ? formatCurrency(data[key].current, currency) : `${data[key].current}`
                          }}
                          <span
                            v-if="objective.type != 'currency'"
                          >
                            {{ data[key].target_value === 1 ? 'Day' : 'Days' }}
                          </span>
                        </div>
                      </b-badge>
                    </div>
                  </div>
                </div>
                <div
                  class="v-center text-center justify-content-between d-flex d-xl-none m-05 mb-0 mx-0"
                >
                  <span>Status:</span>
                  <div>
                    <div v-if="objective.status === 'passing'">
                      <b-badge
                        v-if="data[key].current>=data[key].target_value"
                        variant="danger"
                      >
                        Failed
                      </b-badge>
                      <b-badge
                        v-else
                        variant="success"
                      >
                        {{ state !== 'active' ? 'Passed' : 'Passing' }}
                      </b-badge>
                    </div>
                    <div v-if="objective.status === 'pending'">
                      <b-badge
                        v-if="state === 'active' && data[key].current<data[key].target_value"
                        variant="warning"
                      >
                        Pending
                      </b-badge>
                      <b-badge
                        v-else-if="state==='failed' && data[key].current<data[key].target_value"
                        variant="danger"
                      >
                        Failed
                      </b-badge>
                      <b-badge
                        v-else
                        variant="success"
                      >
                        {{ state !== 'active' ? 'Passed' : 'Passing' }}
                      </b-badge>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BBadge, BButton, BCol, BRow, BPopover, VBPopover, BCard, BCardHeader, BCardTitle, BCardBody, BOverlay,
} from 'bootstrap-vue'
import { get } from 'vuex-pathify'
import { formatCurrency } from '@/plugins/formaters'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BBadge,
    BButton,
    BPopover,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BOverlay,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    data: Object,
    currency: String,
    state: String,
    account: Object,
    onRefresh: Function,
  },
  data() {
    return {
      refreshing: false,
      objectives: {
        min_trading_days: {
          id: 1,
          name: 'Minimum trading days',
          type: 'number',
          status: 'pending',
          text: 'This is the number of active trading days measured in UTC timezone.',
        },
        min_profit: {
          id: 2,
          name: 'Profit target',
          type: 'currency',
          status: 'pending',
          text: 'This value represents your profit in equity.',
        },
        max_daily_drawdown: {
          id: 3,
          name: 'Maximum daily loss',
          type: 'currency',
          status: 'passing',
          text: 'This is the lowest equity drop recorded on one particular day. It contains your floating P/L and will always represent the largest loss on your account.',
        },
        max_total_drawdown: {
          id: 4,
          name: this.account.rule_drawdown_type === 'relative' ? 'Maximum trailing loss' : 'Maximum overall loss',
          type: 'currency',
          status: 'passing',
          text: 'This is the lowest recorded equity of your account from the time you start trading.',
        },
      },
    }
  },
  computed: {
    ...get('appConfig@layout', ['skin']),
  },
  methods: {
    formatCurrency,
    onRefreshClick() {
      this.refreshing = true
      this.onRefresh(['goals']).then(() => {
        this.refreshing = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.collapse-row {
  padding: 10px 0;
  border-bottom: 1px solid grey;

  &:last-child {
    border: none
  }
}
</style>

<style lang="scss">
.goals-overview {
  .card-body {
    padding: 0;
  }
}
</style>
